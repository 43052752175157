<template>
  <main>
    <section class="options">
      <div class="container py-5 row">
        <mega-card class="sm-down:col-12 md-up:col-4">
          <div class="card-body pb-0">
            <mega-range
              class="bg-primary mb-0"
              :label="$t('opt_lifetime_title')"
              v-model.number="lifeTime"
              :min="1"
              :max="1000"
              :step="5"
            />
            <div class="d-flex justify-content-between text-muted">
              <small>1</small>
              <small>1000</small>
            </div>

            <mega-number
              :min="1"
              :max="1000"
              :step="5"
              v-model.number="lifeTime"
              :help="lifeTime"
            />
          </div>
          <div class="card-body col-12">
            <div class="navbar">
              <div class="navbar-group ml-auto">
                <mega-button
                  class="btn-success btn-sm"
                  @click="updateLifeTime"
                  >{{ $t("model_save") }}</mega-button
                >
              </div>
            </div>
          </div>
        </mega-card>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      lifeTime: 1
    };
  },

  mounted() {
    this.$navbar.name = this.$t("options");

    this.getLifeTime();
  },

  methods: {
    getLifeTime() {
      this.$api.v2base
        .get("/config")
        .then(({ data }) => {
          this.lifeTime = data.round_lifetime;
        })
        .catch(({ response }) => {
          switch (response.status) {
            case 403:
              this.$alert.danger("Access denied!");
              break;
            case 500:
              this.$alert.danger("Oops.. Server error");
              break;
          }
        });
    },

    updateLifeTime() {
      this.$api.v2base
        .put("/config", { round_lifetime: this.lifeTime })
        .then(({ data }) => {
          this.lifeTime = data.round_lifetime;
          this.$alert.success("Life time updated!");
        })
        .catch(({ response }) => {
          switch (response.status) {
            case 403:
              this.$alert.danger("Access denied!");
              break;
            case 406:
              this.$alert.danger("Wrong params!");
              break;
            case 500:
              this.$alert.danger("Oops.. Server error");
              break;
          }
        });
    }
  }
};
</script>
