var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", [
    _c("section", { staticClass: "options" }, [
      _c(
        "div",
        { staticClass: "container py-5 row" },
        [
          _c("mega-card", { staticClass: "sm-down:col-12 md-up:col-4" }, [
            _c(
              "div",
              { staticClass: "card-body pb-0" },
              [
                _c("mega-range", {
                  staticClass: "bg-primary mb-0",
                  attrs: {
                    label: _vm.$t("opt_lifetime_title"),
                    min: 1,
                    max: 1000,
                    step: 5
                  },
                  model: {
                    value: _vm.lifeTime,
                    callback: function($$v) {
                      _vm.lifeTime = _vm._n($$v)
                    },
                    expression: "lifeTime"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between text-muted" },
                  [
                    _c("small", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("small", [_vm._v("1000")])
                  ]
                ),
                _vm._v(" "),
                _c("mega-number", {
                  attrs: { min: 1, max: 1000, step: 5, help: _vm.lifeTime },
                  model: {
                    value: _vm.lifeTime,
                    callback: function($$v) {
                      _vm.lifeTime = _vm._n($$v)
                    },
                    expression: "lifeTime"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body col-12" }, [
              _c("div", { staticClass: "navbar" }, [
                _c(
                  "div",
                  { staticClass: "navbar-group ml-auto" },
                  [
                    _c(
                      "mega-button",
                      {
                        staticClass: "btn-success btn-sm",
                        on: { click: _vm.updateLifeTime }
                      },
                      [_vm._v(_vm._s(_vm.$t("model_save")))]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }